(function () {
    const audioPlayerEls = document.querySelectorAll('.js-custom-audio-player');
    if (audioPlayerEls) {
        audioPlayerEls.forEach(audioPlayerEl => {
            const audio = audioPlayerEl.querySelector('.js-custom-audio-player__audio')
            const playButton = audioPlayerEl.querySelector('.js-custom-audio-player-play-button')
            const controlsPauseButton = audioPlayerEl.querySelector('.js-custom-audio-player-controls-pause-button')
            const currentTime = audioPlayerEl.querySelector('.js-custom-audio-player-current')
            const length = audioPlayerEl.querySelector('.js-custom-audio-player-length')
            const progressBar = audioPlayerEl.querySelector(".js-custom-audio-player-seeker")
            const volumeButton = audioPlayerEl.querySelector(".js-custom-audio-player-volume-button")
            const volumeSliderWrapper = audioPlayerEl.querySelector(".js-custom-audio-player-volume-slider-wrapper")
            const volumeSlider = audioPlayerEl.querySelector(".js-custom-audio-player-volume-slider")
            const volumePercentage = audioPlayerEl.querySelector(".js-custom-audio-player-volume-percentage")
            const rewindButton = audioPlayerEl.querySelector(".js-custom-audio-player-rewind")
            const forwardButton = audioPlayerEl.querySelector(".js-custom-audio-player-forward")

            function getTimeCodeFromNum(num) {
                let seconds = parseInt(num)
                let minutes = parseInt(seconds / 60)
                seconds -= minutes * 60
                const hours = parseInt(minutes / 60)
                minutes -= hours * 60

                if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`
                return `${String(hours).padStart(2, 0)}:${minutes}:${String(
                    seconds % 60
                ).padStart(2, 0)}`
            }

            rewindButton.addEventListener('click', e => {
                audio.currentTime -= 15
            })

            forwardButton.addEventListener('click', e => {
                audio.currentTime += 15
            })

            function handleAudioPlayButton() {
                if (audio.paused) {
                    audio.play()
                } else {
                    audio.pause()
                }
            }

            function handleAudioPauseButton() {
                if (audio.play) {
                    audio.pause()
                }
            }

            var ua = window.navigator.userAgent
            var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
            if (iOS) {
                playButton.addEventListener("click", handleAudioPlayButton, false)
                controlsPauseButton.addEventListener("click", handleAudioPauseButton, false)
            }

            audio.addEventListener('loadeddata', e => {
                currentTime.textContent = getTimeCodeFromNum(0)
                length.textContent = getTimeCodeFromNum(audio.duration)

                if (!iOS) {
                    playButton.addEventListener("click", handleAudioPlayButton, false)
                    controlsPauseButton.addEventListener("click", handleAudioPauseButton, false)
                }

                setInterval(() => {
                    progressBar.style.width = audio.currentTime / audio.duration * 100 + "%"
                    currentTime.textContent = getTimeCodeFromNum(
                        audio.currentTime
                    )
                }, 500)
            })

            function volumeSliderInEvent(e) {
                volumeSlider.addEventListener('mousemove', volumeSliderMoveEvent)
                volumeSlider.addEventListener('click', volumeSliderMoveEvent)
                volumeSlider.addEventListener('touchmove', volumeSliderMoveEvent)
            }

            function volumeSliderMoveEvent(e) {
                const sliderWidth = window.getComputedStyle(volumeSlider).width
                let newVolume = e.offsetX / parseInt(sliderWidth)
                newVolume = newVolume < 0 ? 0 : newVolume
                newVolume = newVolume > 1 ? 1 : newVolume
                audio.volume = newVolume
                volumePercentage.style.width = newVolume * 100 + '%'
                if (newVolume <= 0) {
                    volumeButton.classList.remove("c-custom-audio-player__volume-button--on")
                    volumeButton.classList.add("c-custom-audio-player__volume-button--off")
                } else {
                    volumeButton.classList.remove("c-custom-audio-player__volume-button--off")
                    volumeButton.classList.add("c-custom-audio-player__volume-button--on")
                }
            }

            volumeButton.addEventListener('click', e => {
                volumeSliderWrapper.classList.toggle('c-custom-audio-player__volume-slider-wrapper--display')
            })

            volumeSlider.addEventListener('mousedown', volumeSliderInEvent)
            volumeSlider.addEventListener('touchstart', volumeSliderInEvent)

            volumeSlider.addEventListener('mouseleave', e => {
                volumeSlider.removeEventListener('mousemove', volumeSliderMoveEvent)
                volumeSlider.removeEventListener('touchmove', volumeSliderMoveEvent)
            })

            volumeSlider.addEventListener('mouseup', e => {
                volumeSlider.removeEventListener('mousemove', volumeSliderMoveEvent)
                volumeSlider.removeEventListener('touchmove', volumeSliderMoveEvent)
            })
        })
    }
})();